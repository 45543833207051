@font-face {
	font-family: "Aller-Italic";
	src: url("https://anima-uploads.s3.amazonaws.com/597080f9368c28000bccefa3/5d514f7c8757aedef3ee21b0/5d514f7c94d6d6c06d643865/fonts/Aller_It.ttf")
		format("truetype");
}
@font-face {
	font-family: "BebasNeue";
	src: url("https://anima-uploads.s3.amazonaws.com/597080f9368c28000bccefa3/5d514f7c8757aedef3ee21b0/5d514f7c94d6d6c06d643865/fonts/BebasNeue.otf")
		format("opentype");
}
@font-face {
	font-family: "Aller-Bold";
	src: url("https://anima-uploads.s3.amazonaws.com/597080f9368c28000bccefa3/5d514f7c8757aedef3ee21b0/5d514f7c94d6d6c06d643865/fonts/Aller_Bd.ttf")
		format("truetype");
}
@font-face {
	font-family: "Aller";
	src: url("https://anima-uploads.s3.amazonaws.com/597080f9368c28000bccefa3/5d514f7c8757aedef3ee21b0/5d514f7c94d6d6c06d643865/fonts/Aller_Rg.ttf")
		format("truetype");
}
#PDF-REPORT {
	font-family: "Aller";
	nav,
	#footer {
		display: none;
	}
	* {
		box-sizing: border-box;
		font-family: "Aller";
		margin: unset;
	}
	p {
		margin: 1em 0;
	}
	body {
		margin: 0;
		font-family: "Aller";
		background-color: #f0f0f0;
	}
	.PDF-report {
		width: 1280px;
		/* height: 1810px */
		margin: auto;
		position: relative;
		background-color: #ececec;
	}
	header {
		height: 391px;
		background-color: var(--charcoal-grey);
		padding-top: 0px;
		position: relative;
		overflow: hidden;
		text-align: center;
	}
	.page-2 header {
		height: 100px;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: inherit;
	}
	ul {
		margin-left: 2rem;
		margin-top: 1rem;
	}
	h1,
	h2 {
		font-family: "BebasNeue";
		span {
			font-family: "BebasNeue";
			font-weight: 600;
		}
	}
	header h1 {
		margin: 0;
		font-family: "BebasNeue";
		font-size: 70px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		line-height: 80px;
	}
	header h1 span {
		color: var(--dusty-orange);
	}
	.Rectangle-4 {
		width: 50px;
		height: 4px;
		background-color: #43505b;
		margin: auto;
	}
	.Accident-Location {
		margin: 10px auto;
		padding: 5px 10px;
		font-family: Aller;
		font-size: 18px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		border-radius: 5px;
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		display: inline-block;
		text-align: center;
	}
	header .date {
		font-family: Aller;
		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #aaaaaa;
	}
	.Rectangle-5 {
		width: 1200px;
		height: 1px;
		background-color: #43505b;
		margin: 20px auto;
	}
	.case-ids {
		display: flex;
		justify-content: space-evenly;
		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
	}
	.case-ids span {
		color: #f39442;
	}
	.page-2 .case-ids {
		padding-top: 15px;
	}
	header h2 {
		font-family: BebasNeue;
		font-size: 50px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		padding: 10px 0 5px;
		margin: 20px 0 0;
		position: relative;
		z-index: 2;
	}
	header h2 span {
		color: #f39442;
	}
	.triangle-1 {
		width: 0px;
		height: 0px;
		border-top: 83px solid #088da3;
		border-left: 0px solid transparent;
		border-right: 75px solid transparent;
		position: absolute;
		z-index: 0;
	}
	.triangle-2 {
		width: 0px;
		height: 0px;
		border-top: 83px solid #088da3;
		border-right: 0px solid transparent;
		border-left: 83px solid transparent;
		position: absolute;
		z-index: 0;
		right: 0;
	}
	.container {
		width: 1235px;
		margin: -60px auto 0;
		/* height: 1413px; */
		border-radius: 8px;
		box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.18);
		background-color: #ffffff;
		z-index: 2;
		position: relative;
		padding: 18px;
	}
	.page-2 .container {
		padding-top: 0;
		/* height: 1675px; */
	}
	.logo {
		width: 226px;
		max-height: 100px;
		object-fit: contain;
		position: absolute;
		top: 54px;
		left: 11px;
	}
	.created {
		position: absolute;
		font-family: Aller;
		font-size: 16px;
		font-weight: normal;
		font-style: italic;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
		color: #ffffff;
		top: 18px;
		right: 22px;
	}
	.website {
		position: absolute;
		font-family: Aller;
		font-size: 16px;
		text-align: right;
		color: #ffffff;
		top: 18px;
		left: 22px;
		text-decoration: none;
	}
	.map {
		height: 469px;
		margin: auto;
		background: #ccc;
		position: relative;
	}
	.accident-in-short {
		width: 230px;
		border-radius: 8px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
		background-color: rgba(45, 53, 60, 0.9);
		position: absolute;
		right: 18px;
		top: 18px;
		padding: 18px;
		font-size: 16px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
		color: #ffffff;
		overflow: hidden;
	}
	.accident-in-short h3 {
		font-family: BebasNeue;
		margin: 0 0 20px;
		font-size: 30px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: right;
		color: var(--dusty-orange);
	}
	.accident-in-short ul {
		list-style-type: none;
	}
	.at-fault-ribbon {
		width: 150px;
		height: 21px;
		transform: rotate(45deg);
		font-family: BebasNeue;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: right;
		color: #ffffff;
		background: #dc4c48;
		text-align: center;
		position: absolute;
		right: -45px;
		top: 17px;
	}
	.police {
		width: 247px;
		height: 65px;
		border-radius: 8px;
		box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.16);
		background-color: rgba(255, 255, 255, 0.9);
		position: absolute;
		bottom: 18px;
		left: 18px;
		font-size: 12px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: normal;
		color: #999999;
		padding-top: 4px;
	}
	.police img {
		width: 57px;
		height: 57px;
		object-fit: contain;
		float: left;
	}
	.police span {
		font-family: BebasNeue;
		font-size: 18px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #08a8cc;
		margin-top: 3px;
		display: block;
	}
	.conditions {
		width: 271px;
		border-radius: 8px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
		background-color: rgba(255, 255, 255, 0.9);
		position: absolute;
		bottom: 18px;
		right: 18px;
		padding-bottom: 5px;
	}
	.conditions h3 {
		margin: 0;
		border-radius: 8px 8px 0 0;
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		font-family: BebasNeue;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		text-align: center;
		padding-top: 3px;
	}
	.conditions div {
		display: flex;
		font-size: 11px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #a3a3a3;
	}
	.conditions div span {
		text-align: center;
		width: 33.333%;
		display: block;
	}
	.conditions div span img {
		display: block;
		margin: 5px auto;
		height: 26px;
		width: 26px;
		object-fit: contain;
	}
	section {
		/* margin-bottom: 40px; */
		page-break-inside: avoid;
		display: block;
	}
	section h1 {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		font-family: BebasNeue;
		font-size: 40px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		padding: 13px 25px 7px;
		position: relative;
		margin: 18px -18px 48px;
	}
	section h1 span {
		position: relative;
		z-index: 2;
	}
	section h1 span img {
		margin-right: 45px;
		position: relative;
		top: -4px;
		width: 24px;
	}
	section h1:before {
		border-top: 68px solid var(--charcoal-grey);
		border-left: 0px solid transparent;
		border-right: 30px solid transparent;
		height: 0;
		width: 65px;
		content: " ";
		display: block;
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
	}
	.key-facts-icons {
		width: 220px;
		float: left;
	}
	.key-facts-icons .icon {
		font-family: BebasNeue;
		font-size: 20px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		width: 100px;
		height: 100px;
		background-color: var(--charcoal-grey);
		border-radius: 7px;
		background-image: url("./assets/pdf/icon-bg.png");
		background-size: 100%;
		background-repeat: no-repeat;
		background-position: bottom;
		float: left;
		margin-right: 10px;
		margin-bottom: 10px;
	}
	.key-facts-icons .icon h4 {
		font-family: BebasNeue;
		border-radius: 7px 7px 0 0;
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		margin: 0;
		padding: 7px 0 5px;
	}
	.key-facts-icons .icon div {
		font-family: BebasNeue;
		display: flex;
		justify-content: center;
		align-content: center;
		height: 68px;
		font-size: 32px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #f29338;
	}
	.key-facts-icons .icon div img {
		margin: 0 5px;
	}
	.key-facts-block {
		background-color: rgba(102, 102, 102, 0.05);
		border-top: #d8d8d8 4px solid;
		float: right;
		width: calc(100% - 250px);
		padding: 32px 30px;
		font-size: 15px;
		letter-spacing: normal;
		color: #444444;
		overflow: hidden;
	}
	.row {
		flex-direction: row;
		margin-left: -1rem;
		width: calc(100% + 2rem);
		display: flex;
		flex-wrap: wrap;
	}
	.row .column {
		display: block;
		flex: 1 1 50%;
		margin-left: 0;
		max-width: 100%;
		width: 50%;
		padding: 0 1rem;
		position: relative;
		margin-bottom: 20px;
		page-break-inside: avoid;
	}
	.car-holder {
		width: 240px;
		height: 400px;
		border-radius: 8px;
		box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.29);
		border: solid 10px #ffffff;
		background-color: var(--charcoal-grey);
		position: relative;
		float: left;
	}
	.car-holder .vehicle-holder {
		margin: 30px 35px;
	}
	// .car-holder img:not(.attention) {
	// 	width: 162px;
	// 	height: 320px;
	// 	object-fit: contain;
	// 	margin: 30px;
	// }
	// .attention {
	// 	position: absolute;
	// }
	.attention.FC,
	.attention.FD {
		top: 20px;
		left: 92px;
	}
	.attention.FL {
		top: 30px;
		left: 40px;
	}
	.attention.FR {
		top: 30px;
		right: 40px;
	}
	.attention.BC,
	.attention.BD {
		bottom: 20px;
		left: 92px;
	}
	.attention.BL {
		bottom: 34px;
		left: 38px;
	}
	.attention.BR {
		bottom: 34px;
		right: 38px;
	}
	.attention.LP,
	.attention.LD,
	.attention.LT {
		top: 180px;
		left: 28px;
	}
	.attention.RP,
	.attention.RD,
	.attention.RT {
		top: 180px;
		right: 28px;
	}
	.attention.LFQ {
		top: 100px;
		left: 30px;
	}
	.attention.RFQ {
		top: 100px;
		right: 40px;
	}
	.attention.LBQ {
		bottom: 90px;
		left: 30px;
	}
	.attention.RBQ {
		bottom: 90px;
		right: 40px;
	}

	.car-info {
		width: 340px;
		background-color: #f7f7f7;
		float: left;
		border-top: #f29338 4px solid;
		margin-top: 10px;
		position: relative;
		overflow: hidden;
		z-index: -1;
		height: 380px;
	}
	.car-info.full {
		width: 955px;
		min-height: 360px;
	}
	.car-info h2,
	.box h2 {
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		font-family: BebasNeue;
		font-size: 30px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		float: left;
		padding: 10px 20px 7px;
		position: relative;
		margin: 24px 0 0;
	}
	.car-info h2:after,
	.box h2:after {
		border-top: 53px solid #0194bf;
		border-left: 0px solid transparent;
		border-right: 20px solid transparent;
		height: 0;
		width: 16px;
		content: " ";
		display: block;
		position: absolute;
		z-index: 0;
		right: -36px;
		top: 0;
	}
	.car-info.full h2 {
		margin-top: 10px;
	}
	.color {
		clear: both;
		background-color: var(--charcoal-grey);
		font-size: 14px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #ffffff;
		float: left;
		padding: 7px 20px;
		margin-bottom: 10px;
		text-transform: capitalize;
	}
	.car-info p,
	.box p {
		clear: both;
		font-size: 14px;
		color: #666666;
		margin-left: 20px;
	}
	.car-info p strong,
	.box p strong {
		color: var(--charcoal-grey);
	}
	.damage-level {
		width: 114px;
		border-radius: 0 8px 8px 0;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
		font-size: 12px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #ffffff;
		margin-top: 20px;
		padding: 5px;
		position: absolute;
		bottom: 10px;
		left: 256px;
	}
	.damage-level img {
		margin: 5px 0;
	}
	.damage-description {
		color: #666;
		font-size: 14px;
		margin-left: 20px;
	}
	.red {
		background-color: #dc4c48;
	}
	.orange {
		background-color: #f29338;
	}
	.green {
		background-color: #88b644;
	}
	.black {
		background-color: #444444;
	}
	footer {
		background-color: var(--charcoal-grey);
		font-size: 12px;
		font-weight: bold;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #aaaaaa;
		/* position: absolute; */
		bottom: 0;
		width: 100%;
		padding: 18px;
	}
	.box {
		background-color: #f7f7f7;
		border-top: #f29338 4px solid;
		margin: 18px !important;
		padding: 0 !important;
		position: relative;
		overflow: hidden;
		flex: 1 1 47% !important;
	}
	p.alcohol-test {
		font-size: 14px;
		font-weight: bold;
		color: #88b644;
		margin-top: 30px;
	}
	.tow-truck-box {
		width: 202px;
		border-radius: 8px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
		background-color: rgba(255, 255, 255, 0.9);
		margin: 20px;
		font-size: 13px;
		font-weight: bold;
		color: #a3a3a3;
	}
	.tow-truck-box div {
		border-radius: 8px 8px 0 0;
		background-image: linear-gradient(to right, #0fc2dd, #0295bf);
		font-family: BebasNeue;
		font-size: 18px;
		color: #ffffff;
		padding: 6px 13px 4px;
	}
	.tow-truck-box span {
		padding: 13px;
		display: block;
	}
	table {
		clear: both;
		margin: 40px;
	}
	th {
		font-size: 12px;
		font-weight: bold;
		color: #777777;
		padding: 0px 20px;
	}
	th:last-of-type {
		text-align: left;
	}
	td {
		text-align: center;
		font-size: 14px;
		color: #444444;
		padding: 10px 20px;
	}
	td:last-of-type {
		text-align: left;
		width: 480px;
	}
	td strong {
		color: #05a0c6;
	}
	.boxed-icon {
		width: 40px;
		height: 40px;
		border-radius: 7px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: auto;
	}
	.boxed-icon span {
		position: absolute;
		bottom: -11px;
		left: 10px;
		width: 20px;
		height: 20px;
		background-color: #dc4c48;
		font-size: 14px;
		font-weight: bold;
		color: #fff;
		border-radius: 50%;
		text-align: center;
		padding-top: 2px;
	}
	.boxed-icon img {
		width: 22px !important;
		height: 22px !important;
	}
	.vehicle .position.blue {
		background: #88b644;
	}
	// .position-1 {
	// 	position: absolute;
	// 	top: 170px;
	// 	left: 60px;
	// }
	// .position-2 {
	// 	position: absolute;
	// 	top: 170px;
	// 	right: 60px;
	// }
	// .position-3 {
	// 	position: absolute;
	// 	top: 240px;
	// 	left: 60px;
	// }
	// .position-4 {
	// 	position: absolute;
	// 	top: 240px;
	// 	right: 60px;
	// }
	// .truck .position-1 {
	// 	top: 60px;
	// 	left: 60px;
	// }
	// .truck .position-2 {
	// 	top: 60px;
	// 	left: 64px;
	// }
	// .truck .position-3 {
	// 	top: 60px;
	// 	left: 125px;
	// }
	// .motorcycle .position-1 {
	// 	top: 212px;
	// 	left: 91px;
	// 	right: auto;
	// }
	// .motorcycle .position-2 {
	// 	top: 260px;
	// 	left: 91px;
	// 	right: auto;
	// }
	// .car .FRONT_LEFT {
	// 	top: 165px;
	// 	left: 63px;
	// 	right: auto;
	// }
	// .car .FRONT_RIGHT {
	// 	top: 165px;
	// 	left: 120px;
	// 	right: auto;
	// }
	// .car .SECOND_SEAT_LEFT {
	// 	top: 220px;
	// 	left: 63px;
	// 	right: auto;
	// }
	// .car .SECOND_SEAT_CENTER {
	// 	top: 220px;
	// 	left: 92px;
	// 	right: auto;
	// }
	// .car .SECOND_SEAT_RIGHT {
	// 	top: 220px;
	// 	left: 123px;
	// 	right: auto;
	// }
	// .car .THIRD_SEAT_RIGHT {
	// 	top: 280px;
	// 	left: 123px;
	// 	right: auto;
	// }
	// .car .THIRD_SEAT_CENTER {
	// 	top: 280px;
	// 	left: 92px;
	// 	right: auto;
	// }
	// .car .THIRD_SEAT_LEFT {
	// 	top: 280px;
	// 	left: 63px;
	// 	right: auto;
	// }
	#top-buttons {
		text-align: center;
		padding: 10px;
		background: #d8d8d8;
		margin-bottom: 10px;
	}
	#top-buttons button,
	#top-buttons a {
		width: 300px;
		font-family: "BebasNeue";
		font-size: 32px;
		border: none;
		background: #0194bf;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		text-decoration: none;
		padding: 5px;
		border-radius: 0;
	}
	#top-buttons button:hover,
	#top-buttons a:hover {
		background: #0fc2dd;
	}
}
@media print {
	footer {
		position: fixed;
		bottom: 0;
		z-index: 2;
	}
	#top-buttons {
		display: none;
	}
}
