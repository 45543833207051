.containerCharacters {
    width: 100%;
    height: fit-content;
    justify-content: center;
    margin: 1.875rem auto;
    text-align: center;
    gap: 0 !important;
}

.containerCharacters input {
    padding: 0 !important;
    margin: 0 !important;
    opacity: 0;
    &:focus {
        outline: none;
        padding: 0;
    }
}

.character {
    font-family: monospace;
    color: black;
    border-radius: 0.375rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 0.625rem 0;
    font-size: 2.5rem;
    max-width: 3.8rem;
    height: 5.06rem;
    padding: 1rem;
    margin: 0.25rem;
    align-items: center;
    display: flex;
    justify-content: center;
    border: none;
    font-weight: bold;
    background-color: white;
    border: 0.0625rem solid lightgrey;
}
.characterSelected, .characterFilled, .characterInactive {
    border: none;
    outline: none;
    border: 0.0625rem solid lightgrey;
}

@media screen and (min-width: 680px) {
    .character {
        max-width: 5.06rem
    }
};