@media only screen and (max-width: 800px) {
	input:not([type="radio"]) {
		-webkit-appearance: none;
	}
	#header {
		margin-top: 0;
		& > div {
			a {
				display: none;
				&:last-of-type {
					display: inline-block;
					.menu {
						position: relative;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}

	.lejeune {
		.intro {
			h1 {
				font-size: 26px !important;
			}
		}
	}
	.lejeune-gauge {
		width: 180px;
		margin: auto;
	}
	.row {
		display: block;
		width: 100%;
		margin-left: 0;
		&.lejeune {
			display: flex;
			flex-direction: column-reverse;
		}
	}
	#accident-header {
		padding: 10px 0;
		position: relative;
		.container {
			display: block;
			span {
				margin-bottom: 10px;
				margin-right: 10px;
				strong {
					display: inline-block;
					width: 120px;
					margin-right: 0;
				}
			}
		}
	}
	#accident-top {
		position: relative;
		display: block;
		text-align: center;
		margin-top: 0;
		button {
			margin: auto;
			margin-top: 20px;
		}
		& > span {
			margin-left: 0;
			display: block;
		}
		.logo,
		h1 {
			margin-left: 0;
		}
		.logo {
			width: 70px;
		}
	}
	#conditions {
		display: block;
		padding: 10px;
		margin-top: -60px;
		& > div {
			margin-bottom: 15px;
		}
	}
	.menu {
		position: absolute;
		top: 20px;
		left: 20px;
	}
	// .driver-vehicle {
	// 	width: 100%;
	// 	overflow: auto;
	// 	.vehicle {
	// 		zoom: 1;
	// 	}
	// 	.inner {
	// 		width: 900px;
	// 	}
	// }

	.ad-form {
		width: 100%;
		margin-left: 0;
	}
	#modal-root {
		.modal {
			padding: 10px;
			width: 100%;
			left: 0;
			top: 0;
			height: 100vh;
			border-radius: 0;
			.close {
				top: 1%;
				right: 1%;
				&.lejeune {
					top:2%;
					right:3%;
				}
			}
		}
	}
	.steps-container {
		height: 100vh;
		.buttons {
			gap:10px;
			button {
				display: block;
				min-width: 100px;
			}
		}
	}
	.steps-page {
		// za android i chrome desktop bi trebalo 100vh - 100px ... ali avaj
		height: calc(100vh - 170px);
		&.first {
			height: 100vh;
		}
		form {
			margin: 20px 0;
		}
	}
	button {
		padding: 10px 20px;
	}
	.modal {
		h1 {
			font-size: 20px;
		}
	}
	.yes-no {
		margin-top: 20px;
	}
	.yes-no > div {
		display: inline-block;
		width: auto;
	}
	.confirm-part {
		width: 100%;
		> div {
			margin: auto !important;
			padding: 15px 0 !important;
		}
	}
	.important-message {
		margin: 10px 0;
		width: 100%;
		.vehicle {
			margin: auto;
		}
	}
	#notify-me .intro {
		padding: 0 10px;
	}
	#notify-me .intro h1 {
		font-size: 18px;
	}
	.hide-mobile {
		display: none !important;
	}
	.alert-options {
		// margin-top: 0;
		&.hasSelected {
			margin-top: 0;
			.left {
				display: none;
			}
		}
		.option {
			padding: 10px 0px;
			height: auto;
			.check {
				display: none;
			}
			article {
			}
			button {
				float: right;
				margin-top: 10px;
				background: var(--blue);
			}
		}
		.right {
			border: none;
		}
		form {
			margin: 0;
			width: 100%;
		}
	}
	.input-container.half {
		width: 200px;
	}
	.input-container.desktop-half {
		width: 100%;
	}
	.contact-information {
		h2 {
			text-align: left !important;
			margin-top: 10px;
		}
	}
	.general-questions {
		margin-top: 30px;
	}
	.important-message-screen {
		.why {
			float: right;
			img {
				width: 70px;
			}
		}
		.right {
			float: none;
			width: 100%;
		}
	}
	.lead-types-screen {
		.icon {
			margin-right: 20px;
			background: var(--gray);
			padding: 10px;
			border-radius: 10px;
			img {
				width: 20px;
			}
		}
		.call-me {
			width: 40px;
			margin-right: 20;
			opacity: 0.6;
		}
	}
	.lead-types-table {
		h2 {
			font-size: 16px;
		}
		.description {
			font-size: 0.8em;
		}
	}
	.vehicle-picker-arrows {
		display: flex;
		position: absolute;
		flex-direction: row;
		width: 100%;
		justify-content: space-between;
		left: 0px;
		height: 100%;
		pointer-events: none;
		top: 200px;
		a {
			background-color: var(--blue);
			color: #fff;
			display: flex;
			width: 44px;
			height: 44px;
			border-radius: 50%;
			justify-content: center;
			align-items: center;
			pointer-events: all;
			img {
				width: 24px;
				display: block;
			}
			&.left {
				img {
					transform: rotate(90deg);
				}
			}
			&.right {
				img {
					transform: rotate(-90deg);
				}
			}
		}
	}
	#home #header {
		margin-bottom: 0;
	}
	.map-search {
		width: 100%;
		padding: 0;
		box-shadow: none;
		.overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100vw;
			height: 100vh;
			background: rgba(255, 255, 255, 0.2);
			backdrop-filter: blur(2px);
			-webkit-backdrop-filter: blur(2px);
			// transition: all 0.3s;
			opacity: 0;
			pointer-events: none;
			&.show {
				opacity: 1;
				pointer-events: none;
			}
		}
	}
	.container {
		width: 100%;
	}
	.advanced-mobile {
		flex-direction: column;
		transition: all 0.3s;
		position: relative;
		max-height: 0;
		overflow: hidden;
		padding: 5px;
		margin-top: -10px;
		opacity: 0;
		label {
			width: 100% !important;
			display: block;
			margin-bottom: 10px;
			text-align: left;
		}
		&.show {
			max-height: 1000px;
			margin-top: 0;
			opacity: 1;
			overflow: visible;
		}
	}
	#map {
		height: calc(100vh - 400px) !important;
	}
	#footer {
		display: none;
	}
	#home {
		background: #fff;
		h2 {
			margin-left: 10px;
			margin-bottom: 5px;
		}
	}
	.mobile-home-input {
		background-image: url(../src/assets/curve-line.png);
		height: 100px;
		background-size: 115%;
		background-repeat: no-repeat;
		display: flex;
		justify-content: center;
		align-items: center;
		input {
			width: 96%;
			padding: 14px;
			margin-top: 25px;
			border-radius: 5px;
			border: none;
			-webkit-appearance: none;
			font-size: 20px;
		}
	}
	.button-bar {
		position: absolute;
		background: #fff;
		bottom: 20px;
		width: 95%;
		left: 2.5%;
		border-radius: 5px;
		box-shadow: rgba(0, 0, 0, 0.8) 0px 0px 25px;
		padding: 10px;
		button {
			border-radius: 5px;
			display: inline-flex;
		}
		.find-my {
			width: calc(100% - 64px);
			background: var(--blue);
			height: 52px;
		}
		.settings {
			width: 50px;
			margin-left: 10px;
			background: var(--blue);
			text-align: center;
			float: right;
			img {
				width: 28px;
				margin: 0;
			}
		}
		.case-id {
			width: 100%;
			margin-top: 10px;
			background: none;
			color: var(--orange);
			border: var(--orange) 2px solid;
		}
	}
	#results {
		display: block;
		.map-holder {
			height: calc(100vh - 157px);
			display: none;
		}
		.top {
			background: var(--blue);
			margin: 0;
			padding: 10px;
			color: #fff;
			align-items: center;
			span {
				color: #fff;
			}
		}
		.bottom {
			background: var(--green2);
			color: #fff;
			position: fixed;
			bottom: 0;
			width: 100%;
			z-index: 2;
			.back {
				display: none;
			}
			small {
				display: none;
			}
			div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				font-size: 14px;
				.button {
					padding: 5px;
					border-radius: 5px;
					background-color: var(--orange);
					text-transform: uppercase;
					font-size: 15px;
				}
			}
		}
		&.mobile-map {
			.results-list {
				display: none;
			}
			.map-holder {
				display: block;
			}
			.result-preview {
				display: none;
			}
		}
		.result-preview {
			display: none;
		}
	}
	.results-list {
		height: calc(100vh - 151px);
		.result {
			padding: 5px;
			font-size: 15px;
			overflow: hidden;
			> div {
				display: flex;
				strong {
					display: none;
				}
				br {
					display: none;
				}
				.date,
				.vehicle,
				.location {
					margin: 0 5px;
					float: none;
					text-align: left;
				}
				.date {
					width: 130px;
				}
				.vehicle {
					width: 200px;
				}
				.location {
					width: calc(100% - 230px);
					a {
						display: none;
					}
				}
			}
			&.highlighted {
				border-left: none;
			}
			&.legend {
				background: var(--blue);
				color: #fff;
				font-size: 13px;
				text-transform: uppercase;
				font-weight: bold;
				border-top: rgba(255, 255, 255, 0.8) 1px solid;
				.vehicle {
					text-align: center;
				}
				.location {
					text-align: right;
				}
			}
		}
		.button {
			border-radius: 5px;
			padding: 10px 20px;
			display: none;
			text-align: center;
			margin: 10px;
		}
		.highlighted {
			.button {
				display: block;
				&:hover {
					background: var(--blue);
					color: var(--green2);
				}
			}
		}
	}
	.involved-popup {
		position: fixed;
		right: 10px;
		left: auto;
		padding: 10px;
		font-size: 14px;
		&.show {
			top: 125px;
			bottom: auto;
		}
		a {
			color: var(--green2);
			font-size: 16px;
			font-weight: bold;
			display: block;
			margin-top: 10px;
		}
	}
	.react-dropdown-select-input {
		max-width: 80vw !important;
	}
	.top-bar {
		background-color: var(--blue);
		color: #fff;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 5px;
		font-size: 14px;
		button {
			background: none;
			img {
				margin: 0;
			}
		}
		&.header {
			padding: 13px 5px;
			margin-top: 20px;
		}
	}
	.occupant-form-holder {
		margin: 10px 0;
		overflow: auto;
		width: 100vw;
		.inner {
			display: flex;
			justify-content: space-evenly;
			align-items: flex-start;
		}
		.occupant-holder {
			text-align: center;
			font-size: 14px;
			margin: 0 5px;
			border: #ccc 1px solid;
			border-radius: 5px;
			flex: 1;
			span {
				font-weight: bold;
				display: block;
			}
			.head {
				background-color: var(--green2);
				padding: 10px;
				border-top-right-radius: 5px;
				border-top-left-radius: 5px;
				color: #fff;
			}
		}
		.occupant {
			width: auto;
			margin: 0 10px;
			padding: 0;
			position: relative;
			.at-fault {
				top: -10px;
				right: -10px;
				margin: 0;
				border: none;
				width: 68px;
				img {
					width: 100%;
				}
			}
			.inner {
				width: auto;
				display: block;
			}
			.table-holder {
				flex: 0;
				position: inherit;
			}
			.table-holder {
				h2,
				table {
					display: none;
				}
				.buttons {
					display: block;
					button {
						font-size: 14px;
						padding: 5px 0;
						overflow: hidden;
						width: 100%;
						margin-bottom: 10px;
						img {
							width: 16px;
						}
					}
					.white {
						display: none;
					}
				}
			}
			.vehicle {
				zoom: 0.7;
				margin: auto;
			}
		}
		.vehicle-holder {
			margin: 10px auto;
		}
		.compensation-popover {
			display: none;
		}
	}
	#ACCIDENT section {
		margin: 10px;
		border-bottom: var(--blue2) 1px solid;
		padding: 5px 10px;
		font-size: 15px;
	}
	#ACCIDENT section.aligned.triangle {
		margin-left: 4px;
	}
	.triangle-number {
		top: 24px;
	}
	.mobile-cards {
		overflow: auto;
		word-wrap: none;
		white-space: nowrap;
		scroll-snap-type: x mandatory;
		position: relative;
		margin: 10px;
		.cards-inner {
			display: flex;
		}
		&.safety,
		&.driver-info {
			table {
				td:last-of-type,
				th:last-of-type,
				thead {
					display: none;
				}
			}
		}
		&.vehicle-info {
			table {
				td:first-of-type,
				th:first-of-type,
				thead {
					display: none;
				}
			}
		}
		.card {
			position: relative;
			border: #ccc 1px solid;
			min-width: calc(100vw - 20px);
			display: inline-block;
			padding: 10px;
			scroll-snap-align: start;
			border-radius: 10px;
			.occupant,
			.driver-vehicle {
				position: inherit;
				font-size: 14px;
				padding: 0;
				margin: 0;
				border: none;
				table {
					margin-top: 10px;
					td,
					th {
						padding: 5px;
						white-space: break-spaces;
					}
				}
				.buttons {
					display: none;
				}
				.at-fault {
					top: -11px;
					margin: 0px;
					right: -11px;
					width: 68px;
					img {
						width: 100%;
					}
				}
				.vehicle-holder {
					margin: 0;
				}
				.vehicle {
					zoom: 0.5;
				}
				.table-holder {
					margin-left: 10px;
					h2 {
						font-size: 16px;
					}
					section {
						font-size: 14px !important;
						margin-left: 0 !important;
						padding: 5px 0 !important;
						img {
							width: 32px;
							margin-right: 8px !important;
						}
					}
				}
				.vehicle-bg-holder {
					background: none;
					margin: 0;
					padding: 0;
					.vehicle-holder {
						margin: 10px 0;
					}
				}
				.popover {
					display: none;
				}
			}
		}
		.circle {
			width: 36px;
			height: 36px;
			img {
				width: 28px;
			}
		}
	}
	.ad-form {
		padding: 10px;
		margin-bottom: 100px;
	}
	.floating-download {
		position: fixed;
		bottom: 20px;
		left: 5%;
		width: 90%;
		background: var(--green2);
		padding: 20px;
	}
	.card-arrows {
		.left,
		.right {
			position: absolute;
			background: var(--blue);
			margin-top: 95px;
			z-index: 2;
		}
		.left {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
			left: 0px;
			padding: 10px 6px 10px 2px;
			img {
				transform: rotate(90deg);
			}
		}
		.right {
			right: 0;
			padding: 10px 2px 10px 6px;
			img {
				transform: rotate(-90deg);
			}
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
	}
	.home-select__control {
		margin-bottom: 15px;
	}
	#car-lead h1 {
		font-size: 28px;
	}
	.vehicle-list__indicators,
	.home-select__indicators {
		display: none !important;
	}
	#cold-convert-questions {
		section {
			display: block;
		}
		.left,
		.right {
			width: auto;
		}
		.left {
			border-right: none;
			border-bottom: none;
			padding: 15px 0;
		}
		.right {
			padding: 10px 0px 30px 0;
			position: relative;
			div {
				position: absolute;
				background: #fff;
				padding: 10px;
				border: #ccc 1px solid;
				border-radius: 10px;
				box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 15px;
				margin: 0 10px;
				margin-top: -30px;
				pointer-events: none;
				opacity: 0 !important;
			}
			&.hover div {
				opacity: 1 !important;
				pointer-events: all;
			}
			span {
				// display: none !important;
				position: absolute;
				top: 5px;
				left: 62%;
			}
			img {
				width: 90px;
				margin: auto;
				filter: saturate(1) !important;
				opacity: 1 !important;
			}
			.yes-no {
				max-width: 200px;
			}
		}
	}
	#cold-convert-confirm {
		section {
			display: block;
			max-width: 900px;
			margin: auto;
		}
		.left {
			width: auto;
		}
		.right {
			padding-left: 0;
			border-left: none;
			width: auto;
		}
	}
}
