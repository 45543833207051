.request-attorney {
    .steps-information {
      margin-top: 24px;
    }

    .steps-information h3 {
      font-size: 18px;
    }

    .steps-information ol {
      margin-top: 8px;
      margin-left: 16px;
      font-size: 16px;
    }

    .steps-information ol li {
      padding-left: 12px;
      padding-bottom: 4px;
    }

    .call-me {
      display: flex;
      justify-content: center;
      align-items: center;
      gap:20px;
      font-size: 18px;
    }
    .call-me a {
      color: #0eafd9;
    }

    .call-me img {
      width: 84px;
    }

    .buttons {
      &.confirm {
        margin-bottom: 0;
      }
    }

    .left-image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-close {
      z-index: 1000 !important;
    }

    .calculate-btn {
      margin-bottom: 24px;
    }
}



@media only screen and (max-width: 799px) {
  .request-attorney {
    .left-image {
      margin-top: 6px;
      margin-bottom: 40px;
      img {
        width: 74% !important;
      }
    }

    .steps-information {
      margin-top: 32px;
    }

    .calculate-btn {
      margin-bottom: 12px;
    }

    .intro {
      h1 {
        line-height: 1.3;
        letter-spacing: 0.1px;
      }
    }


    .secure-img {
      text-align: center;
      margin-bottom: 12px;
    }
  }
}
