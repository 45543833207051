@import './styles/_request-attorney.scss';

* {
	font-family: "Roboto", sans-serif;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	padding: 0;
	margin: 0;
}
html {
	--blue: #30d0e8;
	--blue2: #e2f1fa;
	--red: #dc4c48;
	--green: #a5c90f;
	--green2: #204e59;
	--gray: #707070;
	--orange: #f89c19;
	--orangeHover: #ffb144;
	--dusty-orange: #f49336;
	--charcoal-grey: #2d353c;
	--disabled: #e1e1e1;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #000;
}
body {
	color: var(--gray);
	&.has-modal {
		overflow: hidden;
	}
}
.row {
	flex-direction: row;
	margin-left: -1rem;
	width: calc(100% + 2rem);
	display: flex;
	> * {
		display: block;
		flex: 1 1 auto;
		margin-left: 0;
		max-width: 100%;
		width: 100%;
		padding: 0 1rem;
	}
}
a {
	color: var(--orange);
	cursor: pointer;
	&:hover {
		color: var(--orangeHover);
	}
}
button,
.button {
	cursor: pointer;
	background: var(--orange);
	color: #fff;
	font-size: 16px;
	font-weight: bold;
	padding: 15px 40px;
	border: transparent 1px solid;
	border-radius: 100px;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	&.outline {
		background: none;
		color: var(--gray);
		text-decoration: underline;
		outline: none;
		&:hover {
			background: none;
			color: #000;
		}
	}
	&.blue {
		background: var(--blue);
		color: var(--green2);
	}
	&.white {
		background: #fff;
		color: #707070;
		border: #707070 1px solid;
		&:hover {
			background: #fff;
			color: var(--blue);
			border: var(--blue) 1px solid;
		}
	}
	&.gray {
		background: #e6e6e6;
		color: #000;
	}
	&.green {
		background: var(--green);
		color: var(--charcoal-grey);
		&:hover {
			background: #b4db15;
			color: var(--charcoal-grey);
		}
	}
	&:hover {
		background: var(--orangeHover);
		color: #fff;
	}
	img {
		margin-right: 10px;
	}
	&[disabled] {
		opacity: 0.5;
		cursor: not-allowed;
		background: #999;
	}
}
.container {
	width: 95%;
	max-width: 1320px;
	margin: auto;
}
#header {
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #fff;
	z-index: 10;
	.logo {
		width: 92px;
	}
	& > div {
		a {
			margin-left: 10px;
			padding: 5px 10px;
			border-radius: 5px;
			color: #000;
			text-decoration: none;
			transition: all 0.3s;
			&:hover {
				background: var(--blue);
				color: #fff;
			}
			&:last-of-type {
				display: none;
			}
		}
	}
}
#home {
	background-repeat: no-repeat;
	background-position: top;
	#header {
		margin-bottom: 100px;
	}
	h1 {
		font-size: 2.5rem;
		margin-bottom: 50px;
		span {
			color: var(--blue);
		}
	}
	h2 {
		text-align: center;
		margin-bottom: 20px;
	}
}
.search-bar,
.map-search {
	padding: 30px;
	border-radius: 1rem;
	margin-bottom: 20px;
	background-color: #fff;
	box-shadow: 0 20px 75px -20px rgba(90, 90, 90, 0.9);
}
.map-search {
	width: 80%;
	margin: auto;
}
.search-bar {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.map-search,
.search-bar {
	label {
		input:not([type="radio"]) {
			width: 100%;
			padding: 5px 10px;
			border: #ccc 1px solid;
			border-radius: 4px;
			height: 40px;
			margin-top: 7px;
			font-size: 1rem;
		}
	}
}
#results {
	margin: 0;
	border-top: #ddd 1px solid;
	display: flex;
	& > div {
		flex: 1;
	}
	.top {
		display: flex;
		justify-content: space-between;
		margin: 10px;
		span {
			color: var(--blue);
			font-weight: bold;
		}
		select {
			border: #ccc 1px solid;
			padding: 5px 10px;
			border-radius: 5px;
		}
	}
	.bottom {
		display: flex;
		background: #f0f0f0;
		align-items: center;
		& > a {
			color: #000;
			flex: 1;
			display: block;
			text-align: center;
			text-decoration: none;
			padding: 50px 0;
		}
		div {
			text-align: center;
			flex: 1;
			padding: 10px;
			.button {
				color: #fff;
				background: #3498db;
			}
		}
	}
	.list-holder {
		position: relative;
		overflow: hidden;
	}
	.result-preview {
		position: absolute;
		top: 0;
		left: 100%;
		background: #fafafa;
		width: 100%;
		height: 100%;
		transition: all 0.3s;
		&.show {
			left: 0;
		}
		.top {
			background: #f1f6fc;
			margin: 0;
			padding: 20px;
			color: #333;
			cursor: pointer;
			div {
				display: flex;
				align-items: center;
				img {
					margin-right: 7px;
				}
			}
		}
		.content {
			height: 100%;
			overflow: auto;
			.ad-form,
			#accident-top,
			#accident-header {
				display: none;
			}
			.blur {
				filter: blur(3px);
				opacity: 0.7;
				overflow: hidden;
				* {
					pointer-events: none;
				}
			}
		}
		.preview-header {
			padding: 20px;
			display: flex;
			justify-content: space-between;
			color: #333;
			img {
				width: 50px;
				float: left;
				margin-right: 10px;
			}
			div:last-of-type {
				width: 170px;
				text-align: right;
			}
			a {
				color: #204e59;
				text-decoration: none;
				font-weight: 600;
			}
			span {
				background: var(--blue);
				color: #fff;
				font-weight: bold;
				padding: 3px 8px;
				border-radius: 15px;
			}
			p {
				margin-bottom: 25px;
			}
		}
		.button {
			position: absolute;
			top: 320px;
			left: calc(50% - 125px);
			width: 250px;
			box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 15px;
			padding: 30px;
		}
	}
}
.results-loading {
	height: calc(100vh - 60px);
	display: flex;
	justify-content: center;
	align-items: center;
}
.results-list {
	height: calc(100vh - 230px);
	overflow: auto;
	.result {
		padding: 15px;
		border-bottom: #eee 1px solid;
		margin-left: 0;
		color: #333;
		border-left: transparent 6px solid;
		transition: all 0.3s;
		overflow: hidden;
		cursor: pointer;
		& > div:last-of-type {
			text-align: right;
		}
		a {
			opacity: 0;
			transition: all 0.3s;
			font-weight: bold;
		}
		&.highlighted {
			border-left-color: var(--blue);
			box-shadow: #3498db66 0px 0px 15px;
			a {
				opacity: 1;
			}
		}
		.date,
		.vehicle {
			float: left;
			clear: left;
			width: 50%;
			text-align: left;
		}
		.vehicle {
			margin-top: 15px;
			width: 50%;
		}
		.location {
			a {
				display: block;
				margin-top: 40px;
			}
		}
	}
	.ad {
		background: #3498db;
		text-decoration: none;
		display: flex;
		padding: 10px;
		justify-content: space-between;
		align-items: center;
		span {
			color: #fff;
			margin-right: 50px;
			&:last-of-type {
				background: var(--orange);
				padding: 5px 10px;
				text-align: center;
				border-radius: 5px;
				font-weight: bold;
				box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 10px;
				margin-right: 0px;
			}
		}
	}
}
.map-holder {
	background: #f0f0f0;
	position: relative;
}
#results-map {
	width: 100%;
	height: 100%;
}
.involved-popup {
	position: absolute;
	bottom: -30px;
	left: 30px;
	background: #fff;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 10px;
	padding: 20px;
	border-radius: 5px;
	opacity: 0;
	transition: all 0.3s;
	&.show {
		opacity: 1;
		bottom: 24px;
	}
}
.share {
	position: absolute;
	bottom: 24px;
	right: 60px;
	background: #fff;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 10px;
	padding: 10px 15px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	color: #333;
	img {
		width: 18px;
		height: 18px;
		margin-right: 7px;
	}
}
.alert-intro-on-map {
	width: 240px;
	background: #fff;
	position: absolute;
	top: 10px;
	left: calc(50% - 120px);
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px;
	padding: 10px 15px;
	border-radius: 5px;
	color: #444;
	font-size: 14px;
	text-decoration: none;
	img {
		width: 90%;
	}
}
.notify-me-on-map {
	position: absolute;
	top: 10px;
	right: 10px;
	text-align: center;
	box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 10px;
	padding: 10px;
	border-radius: 5px;
	color: #444;
	font-size: 12px;
	text-decoration: none;
	background: #3498db;
	color: #fff;
	span {
		display: block;
		font-size: 22px;
		font-weight: bold;
	}
}
.swal2-html-container textarea {
	width: 100%;
	height: 130px;
	font-size: 16px;
	padding: 15px;
	border: #ccc 1px solid;
	border-radius: 5px;
	margin-top: 20px;
}
.swal2-popup.cancelRequest {
	.swal2-confirm {
		width:90%;
		color:var(--charcoal-grey) !important;
		border-radius: 100px !important;
		font-weight: bold;
	}
	.close {
		position: absolute;
		top:15px;
		right:15px;
	}
	label {
		display: block;
		text-align: left;
		margin-left:60px;
		margin-bottom:10px;
		input {
			margin-right: 10px;
		}
	}
}
.swal2-popup.cancelRequestSuccess {
	width:98%;
	max-width: 800px;
}
#accident-top {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	position: sticky;
	top: 0;
	background: #fff;
	z-index: 10;
	.logo {
		width: 92px;
		margin-left: 30px;
	}
	span {
		margin-left: 35px;
		strong {
			color: #000;
		}
	}
	h1 {
		font-size: 24px;
		margin-left: 20px;
		span {
			margin-left: 0;
			color: var(--blue);
		}
	}
	button {
		margin-left: auto;
	}
}
#accident-header {
	background: var(--blue2);
	color: var(--green2);
	padding: 30px 0;
	margin: 20px 0;
	position: sticky;
	top: 68px;
	z-index: 10;
	span {
		margin-right: 30px;
		display: flex;
		align-items: center;
		.badge {
			height: 35px;
			margin: 0 5px;
		}
		strong {
			margin-right: 5px;
		}
	}
	.container {
		display: flex;
	}
}
#ACCIDENT {
	section {
		display: flex;
		align-items: flex-start;
		position: relative;
		strong {
			display: block;
			color: #000;
			margin-bottom: 5px;
		}
		img {
			margin-right: 15px;
		}
		ul:not(.summary) {
			list-style-type: none;
		}
		margin-bottom: 40px;
		&.aligned {
			align-items: center;
			&.triangle {
				margin-left: -4px;
				img {
					margin-right: 12px;
				}
			}
		}
		.summary {
			margin-left: 20px;
			margin-top: 20px;
			li {
				margin-bottom: 5px;
			}
		}
	}
}
#RESULTS #header {
	margin-top: 0;
	height: 60px;
}
#RESULTS #footer {
	display: none;
}
.triangle-number {
	position: absolute;
	color: #fff;
	width: 48px;
	text-align: center;
	display: block;
	top: 12px;
	font-weight: bold;
}
.circle {
	background: #000;
	color: #fff;
	font-weight: bold;
	width: 42px;
	height: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin: 0 14px 0px 0px;
	&.blue {
		background: var(--blue);
	}
	&.green {
		background: var(--green);
	}
	&.orange {
		background: var(--orange);
	}
	&.red {
		background: var(--red);
	}
	&.gray {
		background: #e6e6e6;
	}
}
#conditions {
	background: #fff;
	width: 90%;
	margin: auto;
	margin-top: -45px;
	display: flex;
	justify-content: space-around;
	padding: 20px 0;
	position: relative;
	> div {
		display: flex;
		align-items: center;
	}
	strong {
		color: #000;
		display: block;
	}
	img {
		margin-right: 15px;
	}
}
.occupant {
	margin-bottom: 40px;
	padding-bottom: 40px;
	position: relative;
	border-bottom: #eee 1px solid;
	.inner {
		display: flex;
	}
	.table-holder {
		position: relative;
		flex: 1;
	}
	h2 {
		text-transform: capitalize;
		span {
			color: #707070;
			font-weight: normal;
		}
	}
	table {
		width: 100%;
		border-collapse: collapse;
		margin-top: 20px;
	}
	tr {
		border-top: #eee 1px dashed;
	}
	th {
		color: #000;
		padding: 13px 0;
		text-align: center;
		width: 100px;
		&:last-of-type {
			text-align: left;
			width: auto;
		}
	}
	td {
		padding: 10px 0;
		text-align: center;
		&:last-of-type {
			text-align: left;
		}
	}
	.circle {
		margin: auto;
	}
	.at-fault {
		width: 100%;
		text-align: right;
		border-top: 1px solid var(--red);
		position: absolute;
		margin-top: 20px;
	}
	.buttons {
		display: flex;
		justify-content: flex-start;
		margin-top: 10px;
	}
	button {
		margin-right: 30px;
	}
	.vehicle {
		zoom: 0.8;
	}
}
.vehicle-holder {
	margin: 30px;
}
.vehicle {
	position: relative;
	width: 150px;
	.schema {
		width: 100%;
	}
	.position {
		width: 32px;
		height: 32px;
		border-radius: 50%;
		background: var(--blue);
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		position: absolute;
		&.orange {
			background: var(--orange);
		}
		&.gray {
			background: #e6e6e6;
		}
		&.black {
			background: #000;
		}
		&.boxed {
			border-radius: 6px;
			span {
				position: absolute;
				bottom: -13px;
				left: 7px;
				width: 18px;
				height: 18px;
				background-color: #dc4c48;
				font-size: 14px;
				font-weight: bold;
				color: #fff;
				border-radius: 50%;
				text-align: center;
			}
		}
	}
	img.position {
		background: none;
	}
}
// #ACCIDENT {
.car {
	.position-1 {
		top: 132px;
		left: 32px;
	}
	.position-2 {
		top: 132px;
		left: 87px;
	}
	.position-3 {
		top: 188px;
		left: 29px;
	}
	.position-4 {
		top: 188px;
		left: 90px;
	}
	.position-5 {
		top: 249px;
		left: 32px;
	}
	.position-6 {
		top: 249px;
		left: 87px;
	}
	.FRONT_LEFT {
		top: 132px;
		left: 32px;
	}
	.FRONT_RIGHT {
		top: 132px;
		left: 87px;
	}
	.SECOND_SEAT_LEFT {
		top: 188px;
		left: 29px;
	}
	.SECOND_SEAT_CENTER {
		top: 188px;
		left: 59px;
	}
	.SECOND_SEAT_RIGHT {
		top: 188px;
		left: 90px;
	}
	.THIRD_SEAT_LEFT {
		top: 249px;
		left: 32px;
	}
	.THIRD_SEAT_CENTER {
		top: 249px;
		left: 59px;
	}
	.THIRD_SEAT_RIGHT {
		top: 249px;
		left: 87px;
	}
}

.truck {
	.position-1 {
		top: 65px;
		left: 21px;
	}
	.position-2 {
		top: 65px;
		left: 66px;
	}
	.position-3 {
		top: 65px;
		left: 98px;
	}
}

.bus {
	.position {
		display: none;
	}
	.position-1 {
		top: 48px;
		left: 24px;
		display: flex;
	}
}

.motorcycle {
	.position-1 {
		top: 166px;
		left: 59px;
		display: flex;
	}
	.position-2 {
		top: 205px;
		left: 59px;
		display: flex;
	}
}

.atv {
	.position-1 {
		top: 80px;
		left: 59px;
		display: flex;
	}
	.position-2 {
		top: 120px;
		left: 59px;
		display: flex;
	}
}

.rv {
	.position-1 {
		top: 85px;
		left: 32px;
	}
	.position-2 {
		top: 85px;
		left: 86px;
	}
}

.srs {
	background: var(--orange);
	color: #fff;
	display: none;
	padding: 1px 3px;
	border-radius: 4px;
	font-weight: bold;
	position: absolute;
}
.car {
	.srs {
		display: inline-block;
		&.srs-position-1,
		&.srs-position-FRONT_LEFT {
			&.side {
				top: 136px;
				left: 0px;
				transform: rotate(-90deg);
			}
		}
		&.srs-position-1,
		&.srs-position-FRONT_LEFT {
			&.front {
				top: 110px;
				left: 28px;
			}
		}
		&.srs-position-1,
		&.srs-position-FRONT_LEFT {
			&.back {
				top: 163px;
				left: 28px;
			}
		}

		&.srs-position-2,
		&.srs-position-FRONT_RIGHT {
			&.side {
				top: 136px;
				left: 114px;
				transform: rotate(90deg);
			}
		}
		&.srs-position-2,
		&.srs-position-FRONT_RIGHT {
			&.front {
				top: 110px;
				left: 84px;
			}
		}
		&.srs-position-2,
		&.srs-position-FRONT_RIGHT {
			&.back {
				top: 163px;
				left: 84px;
			}
		}
		&.srs-position-3,
		&.srs-position-SECOND_SEAT_LEFT {
			&.side {
				top: 194px;
				left: 0px;
				transform: rotate(-90deg);
			}
		}
		&.srs-position-3,
		&.srs-position-SECOND_SEAT_LEFT {
			&.front {
				top: 177px;
				left: 28px;
			}
		}
		&.srs-position-3,
		&.srs-position-SECOND_SEAT_LEFT {
			&.back {
				top: 217px;
				left: 28px;
			}
		}
		&.srs-position-4,
		&.srs-position-SECOND_SEAT_RIGHT {
			&.side {
				top: 194px;
				left: 114px;
				transform: rotate(90deg);
			}
		}
		&.srs-position-4,
		&.srs-position-SECOND_SEAT_RIGHT {
			&.front {
				top: 177px;
				left: 84px;
			}
		}
		&.srs-position-4,
		&.srs-position-SECOND_SEAT_RIGHT {
			&.back {
				top: 217px;
				left: 84px;
			}
		}
	}
}
// }
.driver-vehicle {
	margin-bottom: 40px;
	padding-bottom: 40px;
	position: relative;
	border-bottom: #eee 1px solid;
	.inner {
		display: flex;
	}
	.table-holder {
		position: relative;
		flex: 1;
	}
	h2 {
		text-transform: capitalize;
		span {
			color: #707070;
			font-weight: normal;
		}
	}
	table {
		width: 100%;
		border-collapse: collapse;
		margin-top: 20px;
	}
	tr {
		border-top: #eee 1px dashed;
	}
	th {
		color: #000;
		padding: 13px 0;
		text-align: left;
	}
	td {
		padding: 10px 0;
		text-align: left;
		vertical-align: top;
	}
	.at-fault {
		width: 100%;
		text-align: right;
		border-top: 1px solid var(--red);
		position: absolute;
		margin-top: 20px;
	}
	.vehicle-bg-holder {
		background: #f3f3f3;
		padding-top: 1px;
		padding-bottom: 1px;
		padding-left: 40px;
		padding-right: 40px;
		margin: 8px 40px 0px 0;
		position: relative;
		overflow: hidden;
		&.with-popover {
			padding-bottom: 60px;
		}
		.popover {
			background: var(--blue2);
			position: absolute;
			left: 0;
			width: 100%;
			padding: 10px 15px 40px;
			color: var(--green2);
			font-size: 13px;
			transition: all 0.3s;
			transform: translateY(-10px);
			button {
				width: 100%;
				margin-top: 10px;
				font-size: 14px;
			}
			ul {
				margin: 10px 20px 20px;
			}
			.arrow {
				position: absolute;
				right: 15px;
				margin-top: 7px;
				transition: all 0.3s;
			}
			&:hover {
				transform: translateY(-180px);
				.arrow {
					opacity: 0;
				}
			}
		}
	}
}
.compensation-popover {
	position: absolute;
	background: var(--green2);
	color: #fff;
	font-size: 12px;
	padding: 5px;
	width: 240px;
	text-align: center;
	border-radius: 4px;
	margin-top: -40px;
	margin-left: 15px;
	transition: all 0.3s;
	opacity: 0;
	pointer-events: none;
	&.valuation {
		margin-left: 23px;
	}
	&.top-download-button {
		right: 10px;
		top: 47px;
	}
	&:after {
		position: absolute;
		content: "";
		width: 8px;
		height: 8px;
		background: var(--green2);
		bottom: -4px;
		left: calc(50% - 4px);
		transform: rotate(45deg);
	}
	&.show {
		margin-top: -50px;
		opacity: 1;
	}
}
.popover .compensation-popover {
	width: 230px;
}
.damage {
	position: absolute;

	&.car {
		&.FR {
			left: 120px;
			top: 0px;
		}

		&.FL {
			left: 5px;
			top: 0px;
		}

		&.BL {
			left: 5px;
			top: 280px;
		}

		&.BR {
			left: 120px;
			top: 280px;
		}

		&.RBQ {
			left: 120px;
			top: 240px;
		}

		&.LBQ {
			left: 5px;
			top: 240px;
		}

		&.LFQ {
			left: 5px;
			top: 100px;
		}

		&.RFQ {
			left: 120px;
			top: 100px;
		}

		&.LD {
			left: 5px;
			top: 170px;
		}

		&.LP {
			left: 12px;
			top: 30px;
		}

		&.RD {
			left: 120px;
			top: 170px;
		}

		&.RP {
			left: 123px;
			top: 30px;
		}

		&.FC {
			top: 20px;
			left: 65px;
		}

		&.FD {
			top: -10px;
			left: 64px;
		}

		&.BC {
			top: 300px;
			left: 65px;
		}

		&.BD {
			top: 300px;
			left: 65px;
		}

		&.R\&T {
			right: 40px;
			top: 175px;
		}

		&.L\&T {
			left: 40px;
			top: 175px;
		}

		&.none {
			display: none;
		}
	}

	&.motorcycle {
		&.MC {
			left: 63px;
			top: 90px;
		}
		&.none {
			display: none;
		}
	}

	&.truck {
		&.FR {
			left: 129px;
			top: 0px;
		}

		&.FL {
			left: 0px;
			top: 0px;
		}

		&.BL {
			left: 0px;
			top: 500px;
		}

		&.BR {
			left: 129px;
			top: 500px;
		}

		&.RBQ {
			left: 135px;
			top: 380px;
		}

		&.LBQ {
			left: -10px;
			top: 380px;
		}

		&.LFQ {
			left: -10px;
			top: 150px;
		}

		&.RFQ {
			left: 135px;
			top: 150px;
		}

		&.LD {
			left: 0px;
			top: 198px;
		}

		&.LP {
			left: 20px;
			top: 10px;
		}

		&.RD {
			left: 135px;
			top: 198px;
		}

		&.RP {
			left: 100px;
			top: 10px;
		}

		&.FC {
			top: 0px;
			left: 65px;
		}

		&.FD {
			top: 0px;
			left: 65px;
		}

		&.BC {
			top: 500px;
			left: 65px;
		}

		&.BD {
			top: 400px;
			left: 65px;
		}

		&.none {
			display: none;
		}
	}

	&.bus {
		&.FR {
			left: 120px;
			top: 21px;
		}

		&.FL {
			left: 82px;
			top: 21px;
		}

		&.BL {
			left: 82px;
			top: 337px;
		}

		&.BR {
			left: 120px;
			top: 337px;
		}

		&.RBQ {
			left: 137px;
			top: 284px;
		}

		&.LBQ {
			left: 61px;
			top: 284px;
		}

		&.LFQ {
			left: 61px;
			top: 78px;
		}

		&.RFQ {
			left: 137px;
			top: 78px;
		}

		&.LD {
			left: 61px;
			top: 198px;
		}

		&.LP {
			left: 67px;
			top: 22px;
		}

		&.RD {
			left: 137px;
			top: 198px;
		}

		&.RP {
			left: 129px;
			top: 22px;
		}

		&.FC {
			top: 17px;
			left: 100px;
		}

		&.FD {
			top: 17px;
			left: 100px;
		}

		&.BC {
			top: 337px;
			left: 98px;
		}

		&.BD {
			top: 337x;
			left: 98px;
		}

		&.none {
			display: none;
		}
	}

	&.atv {
		&.MC {
			left: 63px;
			top: 100px;
		}
		&.none {
			display: none;
		}
	}

	&.rv {
		&.FR {
			left: 120px;
			top: 0px;
		}

		&.FL {
			left: 0px;
			top: 0px;
		}

		&.BL {
			left: 0px;
			top: 336px;
		}

		&.BR {
			left: 130px;
			top: 336px;
		}

		&.RBQ {
			left: 130px;
			top: 280px;
		}

		&.LBQ {
			left: 0px;
			top: 280px;
		}

		&.LFQ {
			left: 0px;
			top: 120px;
		}

		&.RFQ {
			left: 130px;
			top: 120px;
		}

		&.LD {
			left: 0px;
			top: 198px;
		}

		&.LP {
			left: 40px;
			top: 27px;
		}

		&.RD {
			left: 130px;
			top: 198px;
		}

		&.RP {
			left: 90px;
			top: 27px;
		}

		&.FC {
			top: 7px;
			left: 64px;
		}

		&.FD {
			top: 7px;
			left: 64px;
		}

		&.BC {
			top: 360px;
			left: 64px;
		}

		&.BD {
			top: 360px;
			left: 64px;
		}

		&.none {
			display: none;
		}
	}
}
.ad-form {
	width: 360px;
	margin-left: 60px;
	text-align: center;
	& .error-input {
		border: var(--red) 2px solid !important;
	}
	& .error-message {
		color: var(--red);
		font-size: 14px;
		font-weight: 600;
		display: flex;
	}
	.card-form {
		border: #eee 1px solid;
		border-radius: 5px;
		padding: 20px;
		margin: 5px 0;
		& .error-message {
			margin-top: -24px;
			margin-bottom: 24px;
		}
	}
	p {
		background: #f7f7f7;
		margin: 20px 0;
		padding: 20px 0;
	}
	.ad-form-text {
		display: block;
		font-size: 24px;
		color: #000;
	}
	small {
		text-align: left;
		display: block;
		margin-bottom: 30px;
	}
	button {
		width: 100%;
	}
	.company-logo {
		border-radius: 50%;
		width: 80px;
		height: 80px;
	}
	a {
		color: var(--blue);
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	.ad-form-verification-code {
		& > span {
			font-size: 1rem;
			color: #707070;
			font-weight: 600;
		}
		& .error-message{
			margin-top: -1.2rem;
			margin-bottom: 1.875rem;
		}
		& .tcpa-message {
			margin-top: 1.875rem;
		}
	}
}
form:not(.search-bar):not(.map-search) {
	input:not(.react-dropdown-select-input),
	textarea,
	select,
	.fake-input,
	.button-group {
		border: 1px solid #bfbfbf;
		border-radius: 6px;
		padding: 17px 17px;
		display: block;
		margin-bottom: 30px;
		width: 100%;
		font-size: clamp(16px, 1.1vw, 22px);
		font-weight: bold;
		background: #fff;
		&:focus {
			border-color: var(--blue);
			outline: var(--blue);
		}
	}
	label {
		text-align: left;
		display: block;
		position: absolute;
		background: #fff;
		margin-top: -11px;
		margin-left: 14px;
		padding: 0 10px;
	}
	select {
		appearance: none;
		background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 14px center;
    background-size: 24px;
	}
}
#footer {
	background: #f7f7f7;
	margin-top: 30px;
	padding: 50px 0;
	font-size: 13px;
	text-align: center;
	a {
		color: #707070;
		text-decoration: none;
	}
}
.occupant-form-holder {
	display: flex;
}
.ajax {
	position: relative;
	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(255, 255, 255, 0.7);
		background-image: url(../src/assets/ajax-loader.gif);
		background-repeat: no-repeat;
		background-position: center;
		backdrop-filter: blur(1px);
		-webkit-backdrop-filter: blur(1px);
		cursor: not-allowed;
		z-index: 20;
	}
}
#modal-root {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(2px);
	-webkit-backdrop-filter: blur(2px);
	opacity: 1;
	transition: all 0.3s;
	pointer-events: all;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
	&:empty {
		opacity: 0;
		pointer-events: none;
	}
	.modal {
		background: #fff;
		width: 90%;
		left: 5%;
		padding: 60px;
		opacity: 0.5;
		transition: all 0.3s;
		transform: scale(0) rotateZ(90deg);
		top: 0;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 15px 30px;
		border-radius: 10px;
		&.show {
			top: 10%;
			opacity: 1;
			transform: scale(1) rotateZ(0deg);
		}
		.close {
			position: absolute;
			right: 3%;
			top: 5%;
			z-index: 2;
		}
	}
	#car-lead {
		.content {
			max-height: 90vh;
		}
	}
}
.steps {
	display: flex;
	justify-content: space-evenly;
	text-align: center;
	strong {
		display: block;
	}
	.step {
		flex: 1;
		&.active {
			color: var(--orange);
		}
	}
}
.steps-progress {
	background: #e6e6e6;
	width: 100%;
	height: 6px;
	margin: 10px 0;
	.bar {
		height: 6px;
		background: var(--orange);
		transition: all 0.5s;
	}
}
.steps-container {
	.buttons {
		display: flex;
		justify-content: space-between;
		margin: 30px 0;
		button {
			min-width: 200px;
		}
	}
	position: relative;
	overflow: hidden;
	height: 80vh;
}
.steps-page {
	position: absolute;
	width: 100%;
	height: 97%;
	left: 100%;
	transition: all 0.5s;
	background: #fff;
	padding: 30px 0;
	overflow: auto;
	z-index: 10;
	&.show {
		left: 0;
	}
	form {
		max-width: 520px;
		margin: 50px auto;
	}
	h1 {
		text-align: center;
	}
}
.react-code-input {
	margin: 30px auto;
	text-align: center;
	display: block !important;
	width: 100%;
	input {
		padding: 16px !important;
		width: auto !important;
		height: auto !important;
		display: inline-block !important;
		text-align: center;
		font-size: 40px !important;
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
}
.error {
	color: var(--red);
	font-weight: bold;
	text-align: center;
	margin: 10px;
}
input.err, select.err {
	margin-bottom: 0;
	outline: none;
	border: var(--red) 2px solid !important;
}
.input-container {
	position: relative;
	&.half {
		width: 50%;
	}
	&.desktop-half {
		width: 50%;
	}
}
.input-ok {
	width: 34px;
	height: 34px;
	background: var(--green);
	border-radius: 50%;
	display: block;
	top: 10px;
	right: 16px;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	img {
		width: 14px;
	}
	&.vehicle-make {
		top: 20px;
		right: 2px;
	}
}
.input-error {
	margin-bottom: 30px;
	display: block;
	color: var(--red);
	margin-left: 5px;
	margin-top: -20px;
}
.vehicle-picker {
	display: flex;
	justify-content: space-between;
	margin-top: 20px;
	width: 100%;
	overflow: auto;
	position: relative;
	.vehicle {
		zoom: 0.8;
	}
	& > div {
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-direction: column;
		flex: 1;
		cursor: pointer;
		padding: 20px 20px 0;
		border: transparent 5px solid;
		transition: all 0.3s;
		border-radius: 10px;
		&:hover {
			background: var(--blue2);
		}
		&.selected {
			border: var(--blue) 5px solid;
			background: var(--blue2);
		}
	}
}
.vehicle-picker-arrows {
	display: none;
}
.orange-check-list {
	margin: 20px 0 40px;
	div {
		display: flex;
		margin: 5px 10px;
		align-items: center;
	}
	img {
		width: 16px;
		height: 16px;
		margin-right: 5px;
	}
}
.yes-no {
	text-align: center;
	& > div {
		cursor: pointer;
	}
	a {
		color: #666;
		text-decoration: underline;
	}
}
.yes-no-box {
	border: var(--gray) 2px solid;
	width: 20px;
	height: 20px;
	margin: auto;
	margin-top: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s;
	border-radius: 3px;
	img {
		width: 12px;
		height: 12px;
	}
}
.checked {
	color: #333;
	.yes-no-box {
		cursor: pointer;
		background: var(--blue);
		border: var(--blue) 2px solid;
	}
}
.un-checked-red {
	.yes-no-box {
		background: var(--red);
		border: var(--red) 2px solid;
		cursor: pointer;
		img {
			filter: brightness(10);
		}
	}
}
.button-group {
	& > div {
		margin-top: 10px;
		margin-left: 10px;
		text-align: left;
		cursor: pointer;
		.yes-no-box {
			display: inline-flex;
			margin-right: 10px;
		}
	}
	&.horizontal {
		display: flex;
		& > div {
			margin-right: 15px;
		}
	}
}
#car-brain {
	text-align: center;
	ul {
		list-style-type: none;
		margin: 0;
		text-align: left;
		li {
			margin: 5px;
			img {
				filter: hue-rotate(170deg);
				width: 20px;
				margin-right: 5px;
				position: relative;
				top: 5px;
			}
		}
	}
	.row {
		align-items: center;
	}
	.vehicle {
		margin: auto;
	}
	form {
		overflow: hidden;
		margin-bottom: 30px;
	}
	.content {
		overflow: auto;
		max-height: 80vh;
		position: relative;
	}
	small {
		text-align: justify;
		display: block;
	}
}

#car-lead {
	h1,
	h2,
	h3 {
		color: var(--orange);
		text-align: center;
	}
	ul {
		list-style-type: none;
		margin: 0;
		text-align: left;
		li {
			margin: 5px;
			img {
				filter: hue-rotate(170deg);
				width: 20px;
				margin-right: 5px;
				position: relative;
				top: 5px;
			}
		}
	}
	.row {
		align-items: center;
	}
	.vehicle {
		margin: auto;
	}
	form {
		// overflow: hidden;
		margin-bottom: 30px;
	}
	.buttons {
		display: flex;
		justify-content: space-between;
	}
	.content {
		overflow: auto;
		max-height: 80vh;
		position: relative;
	}
	small {
		text-align: justify;
		display: block;
	}
}

.FL {
	.hide-FL {
		display: none;
	}
}
.TX {
	.hide-TX {
		display: none;
	}
}
nav {
	position: fixed;
	top: 0;
	left: -300px;
	width: 300px;
	height: 100vh;
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	z-index: 12;
	padding: 20px;
	transition: all 0.3s;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px;
	&.show {
		left: 0;
	}
	a {
		display: block;
		margin: 20px 0;
		color: var(--blue);
		text-decoration: none;
	}
	p {
		a {
			margin-left: 20px;
		}
	}
	.logo {
		width: 100px;
		display: block;
		margin: auto;
	}
	.close {
		position: absolute;
		right: 20px;
		top: 0px;
	}
}
.confirm-part {
	width: 90%;
	max-width: 640px;
	margin: auto;
}
.important-message {
	margin: 50px auto 10px;
	width: 90%;
	max-width: 900px;
}
#notify-me {
	.intro {
		padding: 0 60px;
		h1 {
			font-weight: 300;
			color: var(--gray);
			font-size: 26px;
			text-align: left;
			strong {
				color: #000;
			}
			&.black {
				color: #000;
				margin-bottom: 15px;
			}
		}
		.line {
			height: 1px;
			background: #e6e6e6;
			margin: 20px 0;
		}
		button {
			width: 100%;
			margin-top: 30px;
			background: var(--green);
			color: var(--charcoal-grey);
			&:hover {
				background: #d2e487;
			}
		}
	}
	.steps-page {
		h1 {
			text-align: left;
		}
	}
	.orange-back {
		background: #fef0dd;
		color: #000;
		padding: 0 7px;
		border-radius: 4px;
	}
}

.lejeune {
	.intro {
		h1 {
			font-size: 36px !important;
		}
	}
}

.alert-options {
	margin-top: 30px;
	color: #000;
	display: flex;
	.left,
	.right {
		flex: 1;
		border-top: #ddd 1px solid;
	}
	.right {
		border-left: #ddd 1px solid;
	}
	&:hover {
		.option,
		.option-image {
			cursor: pointer;
			.inner {
				opacity: 0.4;
				filter: saturate(0);
			}
			&.hover {
				.inner {
					opacity: 1;
					filter: saturate(1);
				}
			}
		}
	}
	&.hasSelected {
		.option,
		.option-image {
			border-top: none;
			border-bottom: none;
			.inner {
				opacity: 0.3;
			}
			&.selected {
				.inner {
					opacity: 1;
				}
			}
		}
	}
	.option,
	.option-image {
		border-bottom: #ddd 1px solid;
		padding: 30px;
		height: 150px;
		display: flex;
		align-items: center;
		.inner {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex: 1;
			transition: all 0.3s;
		}
		article {
			flex: 1;
			margin-left: 10px;
		}
		.check {
			width: 28px;
			height: 28px;
			background: #f7f7f7;
			border-radius: 50%;
			border: #ddd 1px solid;
		}
		&.selected {
			border: #000 1px solid;
			border-right: var(--blue) 7px solid;
			border-radius: 10px 0 0 10px;
			.inner {
				filter: saturate(1);
			}
			.check {
				border: #000 1px solid;
				&:after {
					content: " ";
					width: 20px;
					height: 20px;
					background: var(--blue);
					border-radius: 50%;
					display: block;
					margin-top: 3px;
					margin-left: 3px;
				}
			}
		}
	}
	.option-image {
		border-right: none;
		padding-left: 0;
		span {
			width: 24px;
			height: 24px;
			background-color: var(--blue2);
			color: #204e59;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		article {
			opacity: 0;
			margin-left: 20px;
			transition: all 0.3s;
		}
		&.hover {
			article {
				opacity: 1;
			}
		}
	}
	form {
		margin: 30px;
		width: calc(100% - 60px);
		max-width: 1000px;
		input {
			font-size: clamp(16px, 1.1vw, 22px);
			font-weight: bold;
		}
	}
	&.leJeJune {
		.left {
			.inner {
				justify-content:center;
				flex-direction: column;
				text-align: center;
			}
			.option {
				opacity: 0.3;
				.inner {
					opacity:1;
				}
			}
			.option.hover {
				opacity: 1;
			}
			.option.selected {
				border-right:none;
				border-left:none;
				border-bottom: #ddd 1px solid;
				border-top:none;
				opacity: 1;
			}
			.yes-no {
				margin-top: 10px;
				max-width: 130px;
			}
		}
		.right {
			.inner {
				img {
					width:110px;
					margin:5px 20px;
				}
			}
		}
		.option-image {
			.inner {
				justify-content: flex-start;
				opacity: 1;
				filter:saturate(1);
			}
			article {
				background-color: #FFF;
				box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
				padding:20px;
				border-radius: 10px;
				pointer-events: none;
				position: absolute;
				// left:180px;
				z-index: 1000;
				width: calc(50% - 200px);
				right:0;
			}
		}
		.option-image:hover, .option:hover {
			cursor: default;
		}
		&.hasSelected .option-image {
			border-bottom: #ddd 1px solid;
		}
	}
}

.buttons.leJeJune {
	max-width: 700px;
	margin:20px auto 0;
	justify-content: center;
}
.buttons.confirm {
	display: block;
	button {
		width: 100%;
		margin-bottom: 10px;
	}
}
#map {
	width: 100%;
}
.vehicle-list__input input {
	margin-top: 12px;
	margin-bottom: 10px;
}
.err.vehicle-list__control {
	border: var(--red) 2px solid !important;
}
.home-select__control {
	margin-top: 7px;
	height: 40px;
}
.home-select__control--is-focused {
	border-color: #ccc !important;
	box-shadow: #ccc 0 0 0 1px !important;
}
.home-select__menu {
	text-align: left;
}
.confirmations {
	color: #000;
	font-size: 20px;
	margin-top: 20px;
	strong {
		display: inline-block;
		width: 150px;
		text-transform: uppercase;
	}
}
.swal2-close {
	outline: none;
}
.circled {
	display: flex;
	background: #eee;
	border-radius: 50%;
	width: 52px;
	height: 52px;
	justify-content: center;
	align-items: center;
	img {
		width: 28px;
	}
}
.selected .circled {
	background: var(--blue2);
}
.rating {
	color: var(--orange);
	margin-top: 10px;
	font-weight: bold;
	font-size: 17px;
	margin-bottom: 5px;
	img {
		position: relative;
		top: 1px;
		&:first-of-type {
			margin-left: 8px;
		}
	}
}
.lead-types-table {
	width: 100%;
	tbody td:first-of-type {
		width: 30px;
		vertical-align: top;
		a {
			border: #ccc 1px solid;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 2px;
			transition: all 0.3s;
			img {
				width: 16px;
				position: relative;
				top: -1px;
				left: 1px;
			}
			&.expand {
				transform: rotate(90deg);
			}
		}
	}
	tbody td:nth-of-type(2) {
		cursor: pointer;
		vertical-align: top;
		padding-top: 8px;
	}
	.description {
		font-size: 0.95em;
		display: block;
		opacity: 0.9;
		margin-top: 0;
		transition: all 0.3s;
		max-height: 0;
		overflow: hidden;
		&.expand {
			max-height: 500px;
			margin-top: 10px;
		}
	}
	td {
		border-bottom: #eee 1px solid;
		padding: 3px 5px 7px;
		&:last-of-type {
			width: 120px;
			text-align: center;
		}
	}
	.yes-no-box {
		cursor: pointer;
	}
}
.am-pm {
	color: #999;
	display: inline-block;
	padding: 2px 7px;
	border: #ccc 1px solid;
	border-radius: 4px;
	margin-left: 5px;
	&.checked {
		background: var(--blue);
		border: var(--blue2) 1px solid;
		color: #fff;
		font-weight: bold;
	}
}
.lead-types-question {
	border: #ddd 1px solid;
	border-radius: 7px;
	margin: 20px 0;
	padding: 35px 25px;
	opacity: 0.2;
	overflow: hidden;
	transition: all 0.3s;
	&.current {
		opacity: 1;
	}
	&.hide {
		height: 0;
		padding: 0;
		margin: 0;
		opacity: 0;
	}
	.title {
		color: #000;
		font-weight: bold;
		margin-bottom: 10px;
		display: block;
	}
	.buttons {
		justify-content: flex-start;
		margin-bottom: 0;
		button {
			background-color: transparent;
			color: #000;
			border: #000 1px solid;
			width: 130px;
			min-width: 0;
			margin-right: 10px;
			&:hover {
				background-color: var(--orange);
				color: #fff;
				border: #fff 1px solid;
			}
		}
	}
}
.lead-types-modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.3s;
	// backdrop-filter: blur(1px);
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	&.show {
		opacity: 1;
		pointer-events: all;
	}
	.content {
		background: #f7f7f7;
		width: 560px;
		box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 25px;
		border-radius: 10px;
		padding: 40px;
		position: relative;
		p {
			margin: 20px 0;
		}
		a {
			display: block;
			margin-top: 10px;
			color: #777;
			text-decoration: underline;
		}
		.img-bg {
			position: absolute;
			bottom: 0;
			left: 380px;
			mix-blend-mode: luminosity;
		}
	}
}
.am-pm-2 {
	background: #e6e6e6;
	display: flex;
	justify-content: space-evenly;
	position: relative;
	padding: 5px;
	margin-bottom: 20px;
	border-radius: 7px;
	width: 300px;
	cursor: pointer;
	.bg {
		position: absolute;
		background: #fff;
		width: calc(50% - 10px);
		height: calc(100% - 10px);
		margin-left: 5px;
		transition: all 0.3s;
		border-radius: 7px;
		left: 0;
		&.PM {
			left: 50%;
		}
	}
	span {
		position: relative;
		z-index: 2;
		flex: 1;
		text-align: center;
		padding: 12px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		font-weight: bold;
		opacity: 0.9;
		img {
			width: 16px;
			height: 16px;
			margin-bottom: 3px;
		}
	}
}
.lead-types-steps {
	color: #000;
	div {
		opacity: 0.2;
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		img {
			margin-right: 7px;
			opacity: 0;
		}
		&.current {
			font-weight: bold;
			opacity: 1;
			img {
				opacity: 0.4;
				filter: saturate(0);
			}
		}
		&.false {
			opacity: 1;
			text-decoration: line-through;
			opacity: 0.5;
			img {
				opacity: 0.4;
				filter: saturate(0);
			}
		}
		&.true {
			opacity: 1;
			color: #a5c90d;
			img {
				opacity: 1;
			}
		}
	}
}
.lead-types-step-bar {
	display: flex;
	justify-content: space-evenly;
	margin-top: 30px;
	div {
		background: #bfbfbf;
		height: 12px;
		flex: 1;
		margin: 0 1px;
		&:first-of-type {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
		&:last-of-type {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
		&.true,
		&.false {
			background: var(--orange);
		}
	}
}
.lead-types-step-text {
	color: #000;
	margin-top: 10px;
}
.contact-information {
	h2 {
		text-align: center !important;
	}
}
.general-questions {
	display: flex;
	justify-content: space-between;
}
.important-message-screen {
	.why {
		float: left;
		padding: 0 25px;
		cursor: pointer;
		text-align: center;
		margin-left: -30px;
		img {
			width: 120px;
		}
	}
	.right {
		float: right;
		width: calc(100% - 220px);
	}
}
.lead-types-screen {
	.icon {
		margin-right: 20px;
		background: var(--gray);
		padding: 10px;
		border-radius: 10px;
		img {
			width: 40px;
		}
	}
	.call-me {
		width: 60px;
		margin-right: 20px;
		opacity: 0.6;
	}
}
.fake-input {
	width: 100%;
	padding: 9px 10px;
	border: #ccc 1px solid;
	border-radius: 4px;
	margin-top: 7px;
	font-size: 1rem;
}
.map-switcher {
	background: #222;
	padding: 5px 15px;
	width: 77px;
	border-radius: 15px;
	position: relative;
	.track {
		display: flex;
		justify-content: space-between;
		svg {
			width: 16px;
			height: 16px;
			fill: #999;
		}
	}
	.but {
		background: var(--orange);
		width: 36px;
		height: 36px;
		border-radius: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: -6px;
		left: 43px;
		box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 5px;
		svg {
			width: 18px;
			height: 18px;
			fill: #fff;
		}
	}
}
.mobile-map {
	.map-switcher {
		.but {
			left: 0px;
		}
	}
}
.react-dropdown-select-input {
	font-size: 1rem !important;
}
#cold-convert-questions {
	&:hover {
		.right img {
			filter: saturate(0);
			opacity: 0.5;
		}
	}
	section {
		display: flex;
		flex-direction: row;
		border-bottom: #ddd 1px solid;
		&:last-of-type {
			border-bottom: none;
		}
		.right {
			div {
				opacity: 0;
				transition: all 0.2s;
			}
			span {
				width: 24px;
				height: 24px;
				background-color: var(--blue2);
				color: #204e59;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 20px;
				padding: 10px;
			}
		}
		&:hover {
			.right div {
				opacity: 1;
			}
			.right img {
				filter: saturate(1);
				opacity: 1;
			}
		}
	}
	margin-top: 30px;
	.left,
	.right {
		width: 50%;
		color: #444;
	}
	.left {
		border-right: #ddd 1px solid;
		padding: 15px 20px 15px 0;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.yes-no {
			max-width: 150px;
			margin: auto;
		}
	}
	.right {
		padding: 15px 0px 15px 30px;
		display: flex;
		align-items: center;
		img {
			width: 100px;
			margin-right: 20px;
			transition: all 0.3s;
		}
	}
	ul {
		margin: 10px 30px;
	}

	input[type="range"] {
		width: 100%;
		max-width: 250px;
		margin-left: 0;
		background-color: transparent;
		-webkit-appearance: none;
		margin-top: 10px;
	}

	/* style datalist */
	input[type="range"] + datalist {
		display: block;
		background-color: transparent;
		-webkit-appearance: none;
		max-width: 250px;
		width: 100%;
	}
	input[type="range"] + datalist option {
		display: inline-block;
		width: calc((100% - 12px) / (var(--list-length) - 1));
		text-align: center;
		font-size: 12px;
		color: var(--blue);
		overflow: visible;
		position: relative;
		&:after {
			content: " ";
			display: block;
			width: 5px;
			height: 20px;
			background-color: var(--blue);
			position: absolute;
			top: -25px;
			left: 50%;
			border-radius: 3px;
			z-index: -1;
		}
	}
	input[type="range"] + datalist option:first-child {
		width: calc((100% - 12px) / ((var(--list-length) - 1) * 2) + 6px);
		text-align: left;
		&:after {
			left: 11px;
		}
	}
	input[type="range"] + datalist option:last-child {
		width: calc((100% - 12px) / ((var(--list-length) - 1) * 2) + 6px);
		text-align: right;
		&:after {
			left: auto;
			right: 11px;
		}
	}
}

input[type="range"]::-webkit-slider-thumb {
	width: 28px;
	height: 28px;
	margin-top: -12px;
	background: var(--blue);
	border-radius: 15px;
	cursor: pointer;
	-webkit-appearance: none;
	border: #204e59aa 2px solid;
}
input[type="range"]::-webkit-slider-runnable-track {
	-webkit-appearance: none;
	background: var(--blue);
	height: 5px;
	border-radius: 3px;
}
#cold-convert-confirm {
	max-width: 800px;
	margin: auto;
	section {
		display: flex;
	}
	.left {
		flex: 1;
		width: 50%;
		text-align: center;
	}
	.right {
		border-left: #ddd 1px solid;
		padding-left: 40px;
		flex: 1;
		padding-top: 30px;
		width: 50%;
		ul {
			margin: 15px 30px;
		}
	}
}
.find-here-button {
	position: absolute;
	top:10px;
	left:10px;
	background-color: var(--orange);
	color:#FFF;
	padding:10px 20px ;
	text-decoration: none;
	border-radius: 5px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px;
	font-weight: bold;
}
.disabled {
	pointer-events: none;
}
.form-center {
	margin: 0 auto !important;
	&>.form-subtitle {
		text-align: center;
		margin: 1rem 0 2rem 0;
	}
}
.group-buttons {
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	gap: 1rem;
	align-items: center;
	& > button {
		width: 15rem;
		border-radius: 0.5rem;
	}
}
.button-disabled {
	background-color: var(--disabled);
	color: var(--charcoal-grey);
	&:hover {
		background-color: var(--disabled);
		opacity: 0.8;
		color: var(--charcoal-grey);
	}
}

.opt-in-form {
	max-width: 43.75rem;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	height: 90%;
	gap: 4rem;
	& > .opt-in-content {
		display: grid;
		gap: 1rem;
		& > hr {
			border: none;
			height: 2px;
			background-color: lightgray;
		}
		& > h1 {
			color: var(--orange);
			font-size: 1.2rem;
			margin-bottom: 1rem;
			text-align: center !important;
		}
		& > h2 {
			font-size: 1rem;
			color: var(--gray);
			text-align: center;
		}
	}
	& .opt-in-checkbox-container {
		display: flex;
		gap: 1rem;
		padding: 0 1rem;
		.opt-in-checkbox {
			height: fit-content;
		}
		& > .opt-in-checkbox:hover {
			cursor: pointer;
		}
		& > span {
			font-size: 0.8rem;
		}
 	}
	& > .opt-in-buttons {
		display: flex;
		justify-content: space-between;
		padding: 0 3rem;
		& > button {
			min-width: 6.25rem;
		}
	}
}

.loading-button {
	opacity: 1 !important;
	background-color: var(--orange) !important;
	display: flex;
	align-items: center;
	gap: 0.25rem;
}

.dots-loader {
	color: #fff !important;
	width: fit-content;
	font-weight: bold;
	clip-path: inset(0 100% 0 0);
	animation: dots 2s steps(11) infinite;
}
.dots-loader:before {
	content:"..."
}

@keyframes dots {
	to {
		clip-path: inset(0 -1ch 0 0)
	}
}

@media only screen and (min-width: 680px) {
	.opt-in-form {
		& > .opt-in-buttons > button {
			min-width: 12.5rem;
		}
		& > .opt-in-content {
			gap: 2rem;
			& > h1 {
				font-size: 1.8rem;
			}
			& > h2 {
				font-size: 1.5rem;
			}
		}
		& .opt-in-checkbox-container {
			& > span {
				font-size: 1rem;
			}
		 }
	}
}

@media only screen and (min-width: 800px) {
	.group-buttons {
		flex-direction: row;
	}
	.hide-desktop {
		display: none !important;
	}
}
@media only screen and (min-width: 992px) {
	.opt-in-form {
		& > .opt-in-buttons {
			padding: 0;
		}
		& .opt-in-checkbox-container {
			padding: 0;
		 }
	}
}

@import "mobile";
@media only screen and (max-width: 600px) {
	// #conditions {
	// 	& > div {
	// 		display: block;
	// 	}
	// }

	.alert-options .option .inner {
		opacity: 1 !important;
		filter: saturate(1) !important;
	}

}
